<script setup lang="ts">
import JSConfetti from 'js-confetti'

onMounted(() => {
    const jsConfetti = new JSConfetti();
    setTimeout(() => {
        jsConfetti.addConfetti({
            emojis: ['🌈', '⚡️', '💥', '✨', '💫', '🌸', '🦄'],
        })
    }, 500);
});
</script>
<template></template>