<script setup>
import SmileIcon from "~/components/Icons/SmileIcon.vue";

const props = defineProps({
    showNotificationSection: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(["preferencesSaved"]);

const store = useDefaultStore();

const user = computed(() => {
    return store.user;
});
const notifyViaEmail = ref(user.value?.allowEmailNotifications ?? false);
const userEmail = ref(user.value?.verifiedEmail ?? "");

const savingUserEmail = ref(false);

function savePreferences() {
    if (!userEmail.value) {
        globalEmit("user:savingPreferencesSkipped");
        return;
    }

    let alreadyVerified = store.user?.verifiedEmail;

    savingUserEmail.value = true;

    apiService
        .setUserEmail({email: userEmail.value})
        .then(() => {
            return apiService.saveBasicSettings({
                allowEmailNotifications: notifyViaEmail.value,
            });
        })
        .then(() => {
            store.loadUser();
            emit("preferencesSaved");

            if (alreadyVerified) {
                globalEmit("user::verifyEmailOtp");
            } else {
                successMessage("Confirmation e-mail was sent.");
            }
        })
        .catch((e) => {
            errorMessage(`Operation failed: ${e.message}`);
        })
        .finally(() => {
            savingUserEmail.value = false;
        });

}

function toggleNotificationsViaEmail() {
    if (!userEmail.value) {
        return;
    }

    notifyViaEmail.value = !notifyViaEmail.value;
}

onMounted(() => {
    globalOn("user:savePreferences", savePreferences);
});

onBeforeUnmount(() => {
    globalOff("user:savePreferences", savePreferences);
});

</script>

<template>
    <div class="text-left">
        <div class=" text-black/50 mb-4">Your Email</div>
        <input v-model="userEmail" type="text" placeholder="E-mail address" class="form-input">
        <div v-if="showNotificationSection" class="flex items-center gap-4 rounded-lg mt-4">
            <div @click="toggleNotificationsViaEmail" :class="{
                            'bg-purple-light': notifyViaEmail,
                            'bg-black/15': !notifyViaEmail,
                        }" class="relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors duration-300">
                        <span :class="{
                            'translate-x-6 fill-black': notifyViaEmail,
                            'translate-x-1 fill-white': !notifyViaEmail,
                            }" class="inline-flex items-center justify-center w-4 h-4 bg-white transform rounded-full transition-transform duration-300"><SmileIcon/></span>
            </div>
            <div>Email Notifications {{ notifyViaEmail ? "Enabled" : "Disabled" }}</div>
        </div>
    </div>
</template>