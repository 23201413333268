<script setup>

import ChevLeftIcon from "~/components/Icons/ChevLeftIcon.vue";
import ChevRightIcon from "~/components/Icons/ChevRightIcon.vue";
import Confetti from "~/components/Confetti.vue";


const props = defineProps({
    animation: Boolean,
    showDoneButton: {
        type: Boolean,
        default: false
    },
    showEmailSlide: Boolean,
});

const emits = defineEmits(["close"]);

const animation = computed(() => props.animation);
const slider = ref(null)
const user = useDefaultStore().user;
const userPreferenceWidget = ref(null)

const slidesCount = computed(() => {
    if (props.showEmailSlide && user?.verifiedEmail == null) {
        return 4;
    }

    return 3;
})

const activeIndex = computed(() => slider.value?.activeIndex);

function doneAction() {

    if (slider.value?.activeIndex == 3) {
        globalEmit("user:savePreferences");
        setTimeout(() => {
            emits("close");
        }, 500);
    } else {
        emits("close");
    }

}

function close() {
    emits("close");
}
</script>

<template>
    <div class="font-semibold text-lg text-center">
        <Confetti v-if="animation"/>
        <div class="relative w-11/12 mx-auto mb-4 lg:mb-8">

            <slot name="top-part">
                <div class="text-black/50 mb-8">How does it work?</div>
            </slot>

            <div class="relative w-full mx-auto mb-2 lg:mb-8">
                <Swiper @swiper="swiper => slider = swiper">
                    <SwiperSlide>
                        <div>
                            <div class="h-40 lg:h-64 flex items-center justify-center">
                                <img src="/images/retina/welcome1.png" srcset="/images/retina/welcome1@2x.png 2x" class=" block mx-auto max-w-full max-h-full" alt=""/>
                            </div>
                            <div class="text-purple text-base lg:text-lg mt-8">Every week a new campaign starts. Post as much or as little as you want.
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <div class="h-40 lg:h-64  flex items-center justify-center">
                                <img src="/images/retina/welcome2.png" srcset="/images/retina/welcome2@2x.png 2x" class=" block mx-auto max-w-full max-h-full" alt=""/>
                            </div>
                            <div class="text-purple text-base lg:text-lg mt-8">Every campaign lasts one week. Your posts will be auto-checked for eligibility and advertiser match.
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <div class="h-40 lg:h-64  flex items-center justify-center">
                                <img src="/images/retina/welcome3.png" srcset="/images/retina/welcome3@2x.png 2x" class=" block mx-auto max-w-full max-h-full" alt=""/>
                            </div>
                            <div class="text-purple text-base lg:text-lg mt-8">Evaluation takes one week. Then, your five best posts are rewarded. Compete to earn more.
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide v-if="slidesCount == 4">
                        <div class="">
                            <div class="h-40 lg:h-64 flex items-center justify-center">
                                <div class="mb-8 w-full lg:w-4/5">
                                    <form @submit.prevent="doneAction">
                                        <ContentsUserPreferencesWidget class="text-sm" ref="userPreferenceWidget" @preferencesSaved="close"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="text-purple text-base lg:text-lg">Make sure you’ll never miss any rewards, by enabling the email notifications. We’ll notify you in a timely matter.</div>
                    </SwiperSlide>

                </Swiper>
            </div>
            <button class="hidden lg:block absolute top-0 -left-8 top-32 bottom-32 px-1" :class="[activeIndex > 0 ? 'fill-black' : 'fill-grey-light']" @click="slider.slidePrev()">
                <ChevLeftIcon/>
            </button>
            <button class="hidden lg:block absolute -right-8 top-32 bottom-32 px-1" :class="[activeIndex < (slidesCount-1) ? 'fill-black' : 'fill-grey-light']" @click="slider.slideNext()">
                <ChevRightIcon/>
            </button>
        </div>

        <div class="flex items-center justify-center gap-2 slider-dots">
            <span v-for="i in slidesCount" class="w-2 h-2 cursor-pointer rounded-full" :class="[(i-1) == activeIndex ? 'bg-purple' : 'bg-black/15']" @click="slider.slideTo(i-1)"></span>
        </div>

        <div v-if="showDoneButton" class="mt-8 text-sm">
            <button class="btn bg-purple-light hover:bg-black hover:text-white" @click="doneAction">Done</button>
        </div>

    </div>
</template>